import React, {useState, useCallback} from 'react';
import moment from "moment";
import { saveAs } from 'file-saver';
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import "moment/locale/ru";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';

moment.locale("ru");

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
}));

function App() {
  const classes = useStyles();

  const [selectedStartDate, handleChangeStartDate] = useState(new Date());
  const [selectedEndDate, handleChangeEndDate] = useState(new Date());

  const handleClickSaveSchedule = useCallback(e=>{
    e.stopPropagation();
    fetch(`https://api.bbmprof.ru/v1/schedules/${moment(selectedStartDate).startOf('day').unix()}/${moment(selectedEndDate).endOf('day').unix()}`, {
      method: "POST",
      mode: "cors"
    })
        .then(r => r.json())
        .then(r => {
          let blob = new Blob([JSON.stringify(r)], {type: "application/json"});
          saveAs(blob)
        })
        .catch(e => console.log(e))
  }, [selectedEndDate, selectedStartDate]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={"ru"}>
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} sm={"auto"} justify={"center"}>
          <KeyboardDatePicker
            autoOk
            variant={"inline"}
            format={"DD.MM.YYYY"}
            label={"Дата начала"}
            value={selectedStartDate}
            onChange={date=>handleChangeStartDate(date)}
            margin={"normal"}
          />
        </Grid>
        <Grid item xs={12} sm={"auto"} justify={"center"}>
          <KeyboardDatePicker
              autoOk
              variant={"inline"}
              format={"DD.MM.YYYY"}
              label={"Дата окончания"}
              value={selectedEndDate}
              onChange={date=>handleChangeEndDate(date)}
              margin={"normal"}
          />
        </Grid>
        <Grid item xs={12} sm={"auto"} justify={"center"}>
          <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<SaveIcon />}
              onClick={handleClickSaveSchedule}
          >
            Сохранить
          </Button>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export default App;
